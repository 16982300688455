import * as React from "react"
import { Container, Carousel } from "react-bootstrap" 
import { StaticImage } from "gatsby-plugin-image"

const iconOne = "../../../images/icon-1.png";
const iconTwo = "../../../images/icon-2.png";
const iconThree = "../../../images/icon-3.png";
const iconFour = "../../../images/icon-4.png";
const iconFive = "../../../images/icon-5.png";
const iconSix = "../../../images/icon-6.png";

function Info() {
	return (
        <div class="about-us">
            <div className="overlay"></div>
        <div className="row about">
            <h1>Our Story</h1>
            <div className="content">
                <p>After working for over 9 years with well-known companies such as Cave Branch Jungle Lodge & Jaguar Paw Resort, Omar Deras and Elmer Medrano, a.k.a. Neko, came together to offer you their licensed Belize tour operator services at the lowest rates possible. They wanted to offer private tours away from the hustle & bustle of  all the cruise ship passengers. 
                In Belize there are only two parks where cave tubing can be done: St. Herman’s Blue Hole National Park and Nohoch che’en Archaeological Reserve....
                
                </p>
                <a href="/about-us" title="About Belize Inland Tours">Read More</a>

            </div>
            <div className="secondary-content">
                <h1>What We <span> OFFER?</span></h1>
            </div>
            <div className="services">
                <div className="row">
                   
                    <div className="col-sm-3">
                    <a href="/tours">
                        <div className="head-icon">
                        <StaticImage src={iconOne} quality={60} formats={["auto", "webp", "avif"]} />
                        </div>
                        <div className="content-last">
                            <h1>Awesome Tours</h1>
                        </div>
                        </a>
                    </div>
                    <div className="col-sm-3">
                        <a href="/shuttle">
                        <div className="head-icon">
                        <StaticImage src={iconThree} quality={60} formats={["auto", "webp", "avif"]} />
                        </div>
                        <div className="content-last">
                            <h1>Shuttle Me</h1>
                        </div>
                        </a>
                    </div>
                    <div className="col-sm-3">
                        <a href="/accommodations">
                        <div className="head-icon">
                        <StaticImage src={iconFour} quality={60} formats={["auto", "webp", "avif"]} />
                        </div>
                        <div className="content-last">
                            <h1>Accomodations</h1>
                        </div>
                        </a>
                    </div>
                    <div className="col-sm-3">
                        <a href="">
                        <div className="head-icon">
                        <StaticImage src={iconFive} quality={60} formats={["auto", "webp", "avif"]} />
                        </div>
                        <div className="content-last">
                            <h1>Custom Packages</h1>
                        </div>
                        </a>
                    </div>
                </div>
            </div>

        </div>

        </div>

    );
}

export default Info;